export const getReplacementItemSettingsFinder = (itemId, secondItemId) => settingsItem => {
  const {
    replacementItemId,
    originalItemId
  } = settingsItem;
  const idSet = new Set([itemId, secondItemId]);
  return idSet.has(String(replacementItemId)) && idSet.has(String(originalItemId));
};
export const getReplacementItemSettings = (item, secondItemId) => {
  const replacementItemSettings = item === null || item === void 0 ? void 0 : item.replacementItemSettings;
  if (!replacementItemSettings) return replacementItemSettings;
  if (!secondItemId) {
    return replacementItemSettings[0];
  }
  return replacementItemSettings.find(getReplacementItemSettingsFinder(String(item._id), secondItemId)) || replacementItemSettings[0];
};
export const findReplacementSettingsForReplacementItem = item => {
  const itemReplacementSettings = item === null || item === void 0 ? void 0 : item.replacementItemSettings;
  if (!itemReplacementSettings) return itemReplacementSettings;
  return itemReplacementSettings.find(settings => settings.replacementItemId === item._id);
};