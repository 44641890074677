import { isBox, isItemBoxSpecialProduct } from 'swag-common/utils/order/item-is-box.util';
import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
export const checkIsCuratedBrandStore = ({
  tenant,
  tenantStoreSettings
}) => {
  var _tenantStoreSettings$;
  if (!checkIsResellerTenant(tenant) || !tenantStoreSettings) {
    return false;
  }
  return Boolean(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : (_tenantStoreSettings$ = tenantStoreSettings.curatedBrandStore) === null || _tenantStoreSettings$ === void 0 ? void 0 : _tenantStoreSettings$.parentId);
};
export const getIsCuratedBrandStoreItem = ({
  item,
  tenant,
  tenantStoreSettings
}) => {
  if (!Object.keys(item).length) {
    return false;
  }
  const isCuratedBrandStoreOrder = checkIsCuratedBrandStore({
    tenant,
    tenantStoreSettings
  });
  const isBoxItself = isBox(item);
  const isSpecialTypeOrAddon = isItemBoxSpecialProduct(item);
  return isCuratedBrandStoreOrder && !isBoxItself && !isSpecialTypeOrAddon;
};