import _flatten2 from "lodash/flatten";
import { ITEM_CLIENT_STATUS_LABEL, PRODUCTION_MOCKUP_STATUSES } from 'swag-common/constants/order-statuses.constants';
import * as ITEM_STATUSES from 'swag-common/constants/item-statuses.constants';
import { RESELLER_ITEM_STATUSES } from 'swag-common/constants/item-statuses-reseller';
import { getClientTextByPaymentMethod } from '../order';
import { getIsCuratedBrandStoreItem } from './is-curated-brand-store-item.utils';
export const shouldUseArtificialStatus = ({
  order,
  item
}) => {
  const isCuratedBrandStoreItem = getIsCuratedBrandStoreItem({
    item,
    tenant: order.tenant,
    tenantStoreSettings: order.tenantStoreSettings
  });
  return isCuratedBrandStoreItem && typeof item.status === 'number' && item.status < ITEM_STATUSES.IN_PRODUCTION;
};
export const getShouldNotifyResellerToReviewPantoneColors = ({
  order,
  item,
  statusCode,
  shouldNotify
}) => {
  const {
    status,
    variants
  } = item;
  const isCuratedBrandStoreItem = getIsCuratedBrandStoreItem({
    item,
    tenant: order.tenant,
    tenantStoreSettings: order.tenantStoreSettings
  });
  const arePantonesPendingApprovalWithData = getArePantonesPendingApprovalWithData({
    status,
    variants
  });
  const arePantonesMissingApprovalData = getArePantonesMissingApprovalData({
    variants
  });
  const isAdminRequestedResellerToReviewPantoneColors = shouldNotify && statusCode === RESELLER_ITEM_STATUSES.COLORS_REVIEW_BY_RESELLER && arePantonesPendingApprovalWithData && !arePantonesMissingApprovalData;
  return !isCuratedBrandStoreItem || isAdminRequestedResellerToReviewPantoneColors;
};
const getArtificialStatuses = ({
  status,
  variants
}) => {
  const isMockupApproved = getIsMockupApproved({
    status,
    variants
  });
  const isMockupRevisionByReseller = getIsMockupRevisionByReseller({
    status,
    variants
  });
  const isMockupPendingApprovalWithData = getIsMockupPendingApprovalWithData({
    status,
    variants
  });
  const isMockupMissingApprovalData = getIsMockupMissingApprovalData({
    variants
  });
  const arePantonesApproved = getArePantonesApproved({
    status,
    variants
  });
  const arePantonesPendingApprovalWithData = getArePantonesPendingApprovalWithData({
    status,
    variants
  });
  const arePantonesMissingApprovalData = getArePantonesMissingApprovalData({
    variants
  });
  return {
    isMockupApproved,
    isMockupRevisionByReseller,
    isMockupPendingApprovalWithData,
    isMockupMissingApprovalData,
    arePantonesApproved,
    arePantonesPendingApprovalWithData,
    arePantonesMissingApprovalData
  };
};
export const getArtificialClientStatus = ({
  status,
  variants,
  paymentMethod
}) => {
  if (typeof status !== 'number') {
    return ITEM_CLIENT_STATUS_LABEL.PROCESSING_ORDER;
  }
  const {
    isMockupApproved,
    isMockupRevisionByReseller,
    isMockupPendingApprovalWithData,
    isMockupMissingApprovalData,
    arePantonesApproved,
    arePantonesPendingApprovalWithData,
    arePantonesMissingApprovalData
  } = getArtificialStatuses({
    status,
    variants
  });
  const isInitial = status === ITEM_STATUSES.INITIAL;
  const isPOReceived = status === ITEM_STATUSES.PO_RECEIVED;
  const isPrepaymentReceived = status === ITEM_STATUSES.PREPAYMENT_RECEIVED;
  const isPaid = status === ITEM_STATUSES.PAID;
  const isSentToVendor = status === ITEM_STATUSES.SENT_TO_VENDOR;
  if (isMockupApproved) {
    return ITEM_CLIENT_STATUS_LABEL.IN_PRODUCTION;
  }
  if (isMockupRevisionByReseller) {
    return ITEM_CLIENT_STATUS_LABEL.CREATING_PRODUCTION_MOCKUP;
  }
  if (isMockupPendingApprovalWithData && !isMockupMissingApprovalData) {
    return ITEM_CLIENT_STATUS_LABEL.VIEW_PRODUCTION_MOCKUP;
  }
  if (isMockupMissingApprovalData && isSentToVendor || arePantonesApproved) {
    return ITEM_CLIENT_STATUS_LABEL.CREATING_PRODUCTION_MOCKUP;
  }
  if (arePantonesPendingApprovalWithData && !arePantonesMissingApprovalData || arePantonesMissingApprovalData && isPaid) {
    return ITEM_CLIENT_STATUS_LABEL.REVIEW_COLORS;
  }
  if (isPrepaymentReceived) {
    return ITEM_CLIENT_STATUS_LABEL.PREPAYMENT_RECEIVED;
  }
  if (isPOReceived) {
    return ITEM_CLIENT_STATUS_LABEL.PO_SENDED;
  }
  if (isInitial) {
    return getClientTextByPaymentMethod(paymentMethod);
  }
  return ITEM_CLIENT_STATUS_LABEL.PROCESSING_ORDER;
};
export const getArtificialStatusCode = ({
  status,
  variants
}) => {
  if (typeof status !== 'number') {
    return RESELLER_ITEM_STATUSES.PROCESSING_ORDER;
  }
  const {
    isMockupApproved,
    isMockupRevisionByReseller,
    isMockupPendingApprovalWithData,
    isMockupMissingApprovalData,
    arePantonesApproved,
    arePantonesPendingApprovalWithData,
    arePantonesMissingApprovalData
  } = getArtificialStatuses({
    status,
    variants
  });
  const isPaid = status === ITEM_STATUSES.PAID;
  const isSentToVendor = status === ITEM_STATUSES.SENT_TO_VENDOR;
  if (isMockupApproved) {
    return RESELLER_ITEM_STATUSES.IN_PRODUCTION;
  }
  if (isMockupRevisionByReseller) {
    return RESELLER_ITEM_STATUSES.MOCKUP_REVISION_BY_RESELLER;
  }
  if (isMockupPendingApprovalWithData && !isMockupMissingApprovalData) {
    return RESELLER_ITEM_STATUSES.MOCKUP_APPROVAL_BY_RESELLER;
  }
  if (isMockupMissingApprovalData && isSentToVendor || arePantonesApproved) {
    return RESELLER_ITEM_STATUSES.CREATING_PRODUCTION_MOCKUP;
  }
  if (arePantonesPendingApprovalWithData && !arePantonesMissingApprovalData || arePantonesMissingApprovalData && isPaid) {
    return RESELLER_ITEM_STATUSES.COLORS_REVIEW_BY_RESELLER;
  }
  return RESELLER_ITEM_STATUSES.PROCESSING_ORDER;
};
export const getArePantonesMissingApprovalData = ({
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length)) {
    return true;
  }
  return variants.some(variant => {
    if (!variant.logos) {
      return true;
    }
    return _flatten2(Object.values(variant.logos)).some(({
      colors
    }) => Boolean(colors) && !colors.isReviewRequestedFromReseller && !colors.isConfirmedByReseller);
  });
};
export const getArePantonesPendingApprovalWithData = ({
  status,
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length) || typeof status !== 'number' || status < ITEM_STATUSES.PREPAYMENT_RECEIVED) {
    return false;
  }
  return variants.some(variant => {
    var _variant$logos;
    return _flatten2(Object.values((_variant$logos = variant.logos) !== null && _variant$logos !== void 0 ? _variant$logos : {})).some(({
      colors
    }) => Boolean(colors) && colors.isReviewRequestedFromReseller && !colors.isConfirmedByReseller);
  });
};
export const getArePantonesApproved = ({
  status,
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length) || typeof status !== 'number' || status < ITEM_STATUSES.PREPAYMENT_RECEIVED) {
    return false;
  }
  return variants.every(variant => {
    var _variant$logos2, _variant$logos3;
    if (!Object.values((_variant$logos2 = variant.logos) !== null && _variant$logos2 !== void 0 ? _variant$logos2 : {}).length) {
      return false;
    }
    return _flatten2(Object.values((_variant$logos3 = variant.logos) !== null && _variant$logos3 !== void 0 ? _variant$logos3 : {})).every(({
      colors
    }) => {
      return colors === null || colors === void 0 ? void 0 : colors.isConfirmedByReseller;
    });
  });
};
export const getIsMockupMissingApprovalData = ({
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length)) {
    return true;
  }
  return variants.some(variant => {
    var _variant$productionMo;
    if (!((_variant$productionMo = variant.productionMockups) !== null && _variant$productionMo !== void 0 && _variant$productionMo.length)) {
      return true;
    }
    return variant.productionMockups.some(({
      status
    }) => status === PRODUCTION_MOCKUP_STATUSES.UPLOADED);
  });
};
export const getIsMockupPendingApprovalWithData = ({
  status,
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length) || typeof status !== 'number' || status < ITEM_STATUSES.SENT_TO_VENDOR) {
    return false;
  }
  return variants.some(variant => {
    var _variant$productionMo2;
    return !!((_variant$productionMo2 = variant.productionMockups) !== null && _variant$productionMo2 !== void 0 && _variant$productionMo2.length) && variant.productionMockups.some(mockup => mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL);
  });
};
export const getIsMockupRevisionByReseller = ({
  status,
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length) || typeof status !== 'number' || status < ITEM_STATUSES.SENT_TO_VENDOR) {
    return false;
  }
  return variants.some(variant => {
    var _variant$productionMo3;
    return !!((_variant$productionMo3 = variant.productionMockups) !== null && _variant$productionMo3 !== void 0 && _variant$productionMo3.length) && variant.productionMockups.some(mockup => mockup.status === PRODUCTION_MOCKUP_STATUSES.DECLINED_BY_RESELLER) && !variant.productionMockups.some(mockup => [PRODUCTION_MOCKUP_STATUSES.UPLOADED, PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL, PRODUCTION_MOCKUP_STATUSES.PENDING_CUSTOMER_APPROVAL, PRODUCTION_MOCKUP_STATUSES.APPROVED_BY_CUSTOMER].includes(mockup.status));
  });
};
export const getIsMockupApproved = ({
  status,
  variants
}) => {
  if (!(variants !== null && variants !== void 0 && variants.length) || typeof status !== 'number' || status < ITEM_STATUSES.SENT_TO_VENDOR) {
    return false;
  }
  return variants.every(variant => {
    var _variant$productionMo4;
    return !!((_variant$productionMo4 = variant.productionMockups) !== null && _variant$productionMo4 !== void 0 && _variant$productionMo4.length) && variant.productionMockups.some(mockup => [PRODUCTION_MOCKUP_STATUSES.PENDING_CUSTOMER_APPROVAL, PRODUCTION_MOCKUP_STATUSES.APPROVED_BY_CUSTOMER].includes(mockup.status));
  });
};