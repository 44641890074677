function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { popupToShowAfterSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
const PREFIX = 'POPUP_MANAGER_';
let hideTimeoutId;
export const POPUP_SHOWN = `${PREFIX}POPUP_SHOWN`;
export const showPopup = _ref => {
  let {
      popup,
      popupToShowAfter = undefined
    } = _ref,
    params = _objectWithoutProperties(_ref, ["popup", "popupToShowAfter"]);
  clearTimeout(hideTimeoutId);
  return {
    type: POPUP_SHOWN,
    payload: {
      popupToShowAfter,
      popup,
      params: _objectSpread({}, params)
    }
  };
};
export const POPUP_HIDDEN = `${PREFIX}POPUP_HIDDEN`;
export const hidePopup = (options = {}) => (dispatch, getState) => {
  const {
    delay = 0,
    shouldControlScroll = true,
    callback,
    callbackDelay
  } = options;
  const state = getState();
  const popupToShowAfter = popupToShowAfterSelector(state);
  const payload = {
    shownPopup: popupToShowAfter || null,
    popupToShowAfter: null
  };
  const delayInt = parseInt(delay, 10);
  if (!Number.isNaN(delay) && delay > 0) {
    hideTimeoutId = setTimeout(() => {
      dispatch({
        type: POPUP_HIDDEN,
        payload
      });
      controlScroll(popupToShowAfter);
    }, delayInt);
    return;
  }
  dispatch({
    type: POPUP_HIDDEN,
    payload
  });
  if (callback) {
    if (callbackDelay) {
      setTimeout(() => {
        callback();
      }, callbackDelay);
    } else {
      callback();
    }
  }
  if (shouldControlScroll) {
    controlScroll(popupToShowAfter);
  }
};
export const POPUP_SET_PARAMS = `${PREFIX}POPUP_SET_PARAMS`;
export const setPopupParams = params => ({
  type: POPUP_SET_PARAMS,
  payload: params
});
function controlScroll(popupToShowAfter) {
  if (popupToShowAfter) {
    return setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 500);
  }
  setTimeout(() => {
    document.body.style.overflow = 'initial';
  }, 500);
}